import { useState, useRef, useEffect, useContext } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material'

// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons'
import Transitions from '../../../components/Transition'
import { UserContext } from '../../../state/userState'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme()
  const { user } = useContext(UserContext)

  const [open, setOpen] = useState(false)

  const anchorRef = useRef(null)

  const prevOpen = useRef(open)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '10px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.dark2,
          backgroundColor: theme.palette.primary.dark2,
          '&:hover': {
            borderColor: theme.palette.primary.dark1,
            backgroundColor: theme.palette.primary.dark1 + ' !important'
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '5px 0 5px 8px !important',
              cursor: 'pointer',
              width: '30px',
              height: '30px',
              background: theme.palette.primary.main,
              color: theme.palette.primary.light3,
              fontWeight: '500'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          >
            {user.firstName && user.firstName[0].toUpperCase()}
            {user.lastName && user.lastName[0].toUpperCase()}
          </Avatar>
        }
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="caption"
              style={{ color: '#fff', marginRight: '5px', fontWeight: '500' }}
            >
              {user.firstName} {user.lastName}
            </Typography>
            {open ? (
              <IconChevronUp stroke={2} size="1.3rem" color={'#fff'} />
            ) : (
              <IconChevronDown stroke={2} size="1.3rem" color={'#fff'} />
            )}
          </div>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ pt: 2, pb: 1, px: 2 }}>
                  <Stack sx={{ pb: 2 }}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="caption">Merhaba,</Typography>
                      <Typography component="span" variant="button">
                        {user.firstName}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="caption">E-posta:</Typography>
                      <Typography variant="subtitle2">{user.email}</Typography>
                    </Stack>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ marginTop: '10px' }}
                      onClick={() => {
                        localStorage.removeItem('user')
                        window.location.href = '/login'
                      }}
                    >
                      Çıkış Yap
                    </Button>
                  </Stack>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default ProfileSection
