import React, { useReducer } from "react";
import { mainReducer } from "./reducer";

const getUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.stringify(user);
  } else {
    return null;
  }
}

const defaultState = {
  user: getUser(),
};

export const UserContext = React.createContext({
  ...defaultState,
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultState);

  const setUser = (payload) => {
    dispatch({ type: "SET_USER", payload });
  };

  const value = {
    user: state.user,
    setUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
