import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./main";
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider } from "./state/state";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserProvider } from "./state/userState";

const root = ReactDOM.createRoot(document.getElementById("root"));

const outerTheme = createTheme({
  palette: {
    primary: {
      light1: "#36CEFF",
      light2: "#7BDFFF",
      light3: "#DAF6FF",
      main: "#00ADE5",
      dark1: "#1F5296",
      dark2: "#193C6A",
    },
  },
});

root.render(
  <React.Suspense fallback={false}>
    <UserProvider>
      <GlobalProvider>
        <ThemeProvider theme={outerTheme}>
          <ToastContainer />
          <Main />
        </ThemeProvider>
      </GlobalProvider>
    </UserProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
