import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { CheckCircleOutline, ErrorOutlineOutlined, InfoOutlined } from '@mui/icons-material'
import { Alert } from '@mui/material'
import { MainContext } from '../../state/state'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal() {
  const { modal, setModal } = useContext(MainContext)
  const handleClose = () => {
    setModal(false)
  }
  if (!modal) return false
  return (
    <Dialog
      open={modal}
      TransitionComponent={Transition}
      keepMounted
      onClose={!modal.notClose && handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {modal.type === 'success' && (
        <Alert
          icon={<CheckCircleOutline fontSize="inherit" />}
          severity="success"
          sx={{ alignItems: 'center' }}
        >
          <DialogTitle>{modal.title}</DialogTitle>
        </Alert>
      )}
      {modal.type === 'error' && (
        <Alert
          icon={<ErrorOutlineOutlined fontSize="inherit" />}
          severity="error"
          sx={{ alignItems: 'center' }}
        >
          <DialogTitle>{modal.title}</DialogTitle>
        </Alert>
      )}
      {modal.type === 'info' && (
        <Alert
          icon={<InfoOutlined fontSize="inherit" />}
          severity="info"
          sx={{ alignItems: 'center' }}
        >
          <DialogTitle>{modal.title}</DialogTitle>
        </Alert>
      )}
      {modal.type === 'warning' && (
        <Alert icon={<ErrorOutlineOutlined />} severity="warning" sx={{ alignItems: 'center' }}>
          <DialogTitle>{modal.title}</DialogTitle>
        </Alert>
      )}
      {!modal.type && <DialogTitle>{modal.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{modal.message}</DialogContentText>
        {modal.children && modal.children}
      </DialogContent>
      <DialogActions>
        {modal.cancelButton && (
          <Button
            disabled={modal.cancelButton.disabled}
            onClick={() => {
              modal.cancelButton.onClick && modal.cancelButton.onClick()
              handleClose()
            }}
          >
            {modal.cancelButton.text}
          </Button>
        )}
        {modal.confirmButton && (
          <Button
            disabled={modal.confirmButton.disabled}
            onClick={() => {
              modal.confirmButton.onClick && modal.confirmButton.onClick()
              handleClose()
            }}
          >
            {modal.confirmButton.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
