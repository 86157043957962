// material-ui

import { useEffect, useState } from 'react'

import { Typography } from '@mui/material'

// project imports

import {
  IconDashboard,
  IconBuildingArch,
  IconCarouselHorizontal,
  IconCategory,
  IconHistory,
  IconBrandDeviantart,
  IconNumbers,
  Icon360View,
  IconPhoneCall,
  IconUsers
} from '@tabler/icons'

import NavGroup from './NavGroup'

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
  const [menuItem, setMenuItem] = useState()

  useEffect(() => {
    setMenuItem(getMenuItems())
  }, [])

  if (!menuItem) return false

  return (
    <>
      {menuItem.map((item) => {
        if (!item) return false

        switch (item.type) {
          case 'group':
            return <NavGroup key={item.id} item={item} />

          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                Menu Items Error
              </Typography>
            )
        }
      })}
    </>
  )
}

export default MenuList

function getMenuItems() {
  const returnVal = []

  returnVal.push({
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'panelUsers',
        title: 'Dashboard',
        type: 'item',
        url: '/',
        icon: IconDashboard,
        breadcrumb: false
      }
    ]
  })

  const role = JSON.parse(window.localStorage.getItem('user')).role
  if (Number(role) === 1) {
    returnVal.push({
      id: 'userManagement',
      title: 'Kullanıcı Yönetimi',
      type: 'group',
      children: [
        {
          id: 'users',
          title: 'Panel Kullanıcıları',
          type: 'item',
          url: '/users',
          icon: IconUsers,
          breadcrumb: false
        }
      ]
    })
  }

  returnVal.push({
    id: 'contentManagement',
    title: 'İçerik Yönetimi',
    type: 'group',
    children: [
      {
        id: 'projects',
        title: 'Projeler',
        type: 'item',
        url: '/projects',
        icon: IconBuildingArch,
        breadcrumb: false
      },
      {
        id: 'categories',
        title: 'Kategoriler',
        type: 'item',
        url: '/categories',
        icon: IconCategory,
        breadcrumb: false
      },
      {
        id: 'sliders',
        title: 'Sliderlar',
        type: 'item',
        url: '/sliders',
        icon: IconCarouselHorizontal,
        breadcrumb: false
      },
      {
        id: 'history',
        title: 'Tarihçe',
        type: 'item',
        url: '/history',
        icon: IconHistory,
        breadcrumb: false
      },
      {
        id: 'services',
        title: 'Hizmetler',
        type: 'item',
        url: '/services',
        icon: IconBrandDeviantart,
        breadcrumb: false
      },
      {
        id: 'numbers',
        title: 'Rakamlar',
        type: 'item',
        url: '/numbers',
        icon: IconNumbers,
        breadcrumb: false
      },
      {
        id: 'about',
        title: 'Çalışmalarımız Hakkında',
        type: 'item',
        url: '/about',
        icon: Icon360View,
        breadcrumb: false
      },
      {
        id: 'contact',
        title: 'İletişim',
        type: 'item',
        url: '/contact',
        icon: IconPhoneCall,
        breadcrumb: false
      }
    ]
  })

  return returnVal
}
