import React, { useReducer } from "react";
import { mainReducer } from "./reducer";

const defaultState = {
  loading: false,
  snackbar: false,
  modal: false,
};

export const MainContext = React.createContext({
  ...defaultState,
});

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultState);

  const setLoading = (payload) => {
    dispatch({ type: "SET_LOADING", payload });
  };

  const setSnackbar = (payload) => {
    dispatch({ type: "SET_SNACK", payload });
  };

  const setModal = (payload) => {
    dispatch({ type: "SET_MODAL", payload });
  };

  const value = {
    loading: state.loading,
    snackbar: state.snackbar,
    modal: state.modal,

    setLoading,
    setSnackbar,
    setModal,
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
