import { useState } from "react";
//import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import NavItem from "../NavItem";

// assets
import { IconChevronDown, IconChevronUp } from "@tabler/icons";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();
  //const customization = useSelector((state) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse
            key={item.id}
            menu={item}
            level={level + 1}
          />
        );
      case "item":
        return (
          <NavItem
            key={item.id}
            item={item}
            level={level + 1}
          />
        );
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          >
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `10px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: "transparent",
          py: 1,
          pl: `24px`,
          "&:hover": {
            backgroundColor: theme.palette.primary.light3,
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.light2,
            color: theme.palette.primary.dark2,
          },
          "&.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.light2,
          }
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
          <Icon
            stroke={2}
            size="1.5rem"
            color={theme.palette.primary.dark2}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              color="inherit"
              sx={{ marginLeft: "10px", fontSize: "14px", fontWeight: "500" }}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp
            stroke={2}
            size="1.2rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        ) : (
          <IconChevronDown
            stroke={2}
            size="1.2rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        )}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            width: "90%",
            float: "right",
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
