import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { MainContext } from "../../state/state";

export default function Loading({direct}) {
  const {loading} = useContext(MainContext);

  if(!loading && !direct) return null
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 99999999 }}
      open={loading || direct}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
