import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, useMediaQuery } from "@mui/material";

// project imports
import LogoSection from "./LogoSection";
import ProfileSection from "./ProfileSection";

// assets
import { IconMenu2 } from "@tabler/icons";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: "220px",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            borderRadius: matchUpMd ? "1vw" : "10px",
            overflow: "hidden",
            width: matchUpMd ? "3vw" : "50px",
            height: matchUpMd ? "3vw" : "50px",
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.primary.light2,
              color: theme.palette.primary.dark1,
              "&:hover": {
                background: theme.palette.primary.dark1,
                color: theme.palette.primary.light2,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2
              stroke={1.5}
              size="1.3rem"
            />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      <ProfileSection />
    </>
  );
};

export default Header;
