export const mainReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SNACK":
      return { ...state, snackbar: action.payload };
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_MODAL":
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};
