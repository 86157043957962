import React, { useContext, useState } from 'react'

// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

// project imports
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'

import Loading from './components/Loading'
import Modal from './components/Modal'
import Header from './MainLayout/Header'
import Sidebar from './MainLayout/Sidebar'
import { UserContext } from './state/userState'
import { isMobileRef } from './helpers/refs'

//Auth
const Login = React.lazy(() => import('./screens/Login'))

// Dashboard
const Dashboard = React.lazy(() => import('./screens/Dashboard'))

// Projects
const Projects = React.lazy(() => import('./screens/Projects'))
const CretaeProject = React.lazy(() => import('./screens/Projects/CreateProject'))
const EditProject = React.lazy(() => import('./screens/Projects/EditProject'))

// Categories
const Categories = React.lazy(() => import('./screens/Categories'))
const CreateCategory = React.lazy(() => import('./screens/Categories/CreateCategory'))
const EditCategory = React.lazy(() => import('./screens/Categories/EditCategory'))

// Sliders
const Sliders = React.lazy(() => import('./screens/Sliders'))
const CreateSlider = React.lazy(() => import('./screens/Sliders/CreateSlider'))
const EditSlider = React.lazy(() => import('./screens/Sliders/EditSlider'))

// History
const History = React.lazy(() => import('./screens/History'))

// Services
const Services = React.lazy(() => import('./screens/Services'))
const CreateService = React.lazy(() => import('./screens/Services/CreateService'))
const EditService = React.lazy(() => import('./screens/Services/EditService'))

// Numbers
const Numbers = React.lazy(() => import('./screens/Numbers'))
const CreateNumber = React.lazy(() => import('./screens/Numbers/CreateNumber'))
const EditNumber = React.lazy(() => import('./screens/Numbers/EditNumber'))

// About
const About = React.lazy(() => import('./screens/About'))

// Contact
const Contact = React.lazy(() => import('./screens/Contact'))

// Users
const Users = React.lazy(() => import('./screens/Users'))
const CreateUser = React.lazy(() => import('./screens/Users/CreateUser'))
const EditUser = React.lazy(() => import('./screens/Users/EditUser'))

// 403 Page
const ForbiddenPage = React.lazy(() => import('./screens/ErrorPages/403'))

// styles
const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, isauth }) => {
  const location = useLocation()

  if (location.pathname.includes('403')) {
    return { width: '100%' }
  }

  return {
    ...(isauth === 0 && {
      width: '100%'
    }),
    ...(isauth === 1 && {
      ...theme.typography.mainContent,
      marginTop: '80px',
      paddingTop: '2%',
      paddingLeft: '2%',
      paddingRight: '2%',
      backgroundColor: 'rgb(238, 242, 246)',
      minHeight: 'calc(100vh - 80px)',
      borderTopLeftRadius: '15px',
      ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
          marginLeft: '-15vw',
          width: 'calc(100% + 15vw)'
        },
        [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: 'calc(100% - 20px)',
          padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '10px',
          width: 'calc(100% - 10px)',
          padding: '16px',
          marginRight: '10px'
        }
      }),
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 15vw)`,
        [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
        }
      })
    })
  }
})

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext)

  if (!user) {
    return <Navigate to="/login" replace />
  }
  return React.cloneElement(children)
}

export default function Navigation() {
  const { user } = useContext(UserContext)

  const theme = useTheme()
  isMobileRef.current = useMediaQuery(theme.breakpoints.down('sm'))
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(true)

  const handleLeftDrawerToggle = () => {
    setLeftDrawerOpened(!leftDrawerOpened)
  }

  const NavigationBar = () => {
    const location = useLocation()

    if (!user || location.pathname.includes('403')) return false

    return (
      <>
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
          }}
        >
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      </>
    )
  }

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavigationBar />

        <MainStyle theme={theme} open={leftDrawerOpened} isauth={user ? 1 : 0}>
          <Modal />
          <Loading />
          <Routes>
            {/* Dashboard */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
              exact
            />
            {/* Projects */}
            <Route
              path="/projects"
              element={
                <ProtectedRoute>
                  <Projects />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/projects/create"
              element={
                <ProtectedRoute>
                  <CretaeProject />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/projects/:id"
              element={
                <ProtectedRoute>
                  <EditProject />
                </ProtectedRoute>
              }
              exact
            />
            {/* Categories */}
            <Route
              path="/categories"
              element={
                <ProtectedRoute>
                  <Categories />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/categories/create"
              element={
                <ProtectedRoute>
                  <CreateCategory />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/categories/:id"
              element={
                <ProtectedRoute>
                  <EditCategory />
                </ProtectedRoute>
              }
              exact
            />
            {/* Sliders */}
            <Route
              path="/sliders"
              element={
                <ProtectedRoute>
                  <Sliders />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/sliders/create"
              element={
                <ProtectedRoute>
                  <CreateSlider />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/sliders/:id"
              element={
                <ProtectedRoute>
                  <EditSlider />
                </ProtectedRoute>
              }
              exact
            />
            {/* History */}
            <Route
              path="/history"
              element={
                <ProtectedRoute>
                  <History />
                </ProtectedRoute>
              }
              exact
            />
            {/* Services */}
            <Route
              path="/services"
              element={
                <ProtectedRoute>
                  <Services />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/services/create"
              element={
                <ProtectedRoute>
                  <CreateService />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/services/:id"
              element={
                <ProtectedRoute>
                  <EditService />
                </ProtectedRoute>
              }
              exact
            />
            {/* Numbers */}
            <Route
              path="/numbers"
              element={
                <ProtectedRoute>
                  <Numbers />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/numbers/create"
              element={
                <ProtectedRoute>
                  <CreateNumber />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/numbers/:id"
              element={
                <ProtectedRoute>
                  <EditNumber />
                </ProtectedRoute>
              }
              exact
            />
            {/* About */}
            <Route
              path="/about"
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
              exact
            />
            {/* Contact */}
            <Route
              path="/contact"
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
              exact
            />
            {/* Users */}
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/users/create"
              element={
                <ProtectedRoute>
                  <CreateUser />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/users/:id"
              element={
                <ProtectedRoute>
                  <EditUser />
                </ProtectedRoute>
              }
              exact
            />
            {/* Auth */}
            <Route path="/login" element={<Login />} exact />
            <Route path="/403" element={<ForbiddenPage />} exact />
          </Routes>
        </MainStyle>
      </Box>
    </Router>
  )
}
