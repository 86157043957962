// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import Logo from "./logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase
    disableRipple
    sx={{ width: "100%", pl: 1, pr: 1 }}
  >
    <Logo />
  </ButtonBase>
);

export default LogoSection;
