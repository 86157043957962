import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const [activePathName, setActivePathName] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setActivePathName(location.pathname);
  }, [location]);

  const Icon = item.icon;

  const itemHandler = (item) => {
    navigate(item.url);
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      //dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        borderRadius: `10px`,
        mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: "transparent",
        py: 1,
        pl: `24px`,
        color: theme.palette.primary.dark2,
        "&:hover": {
          backgroundColor: theme.palette.primary.light3,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.light2,
          color: theme.palette.primary.dark2,
        },
        "&.Mui-selected:hover": {
          backgroundColor: theme.palette.primary.light2,
        }
      }}
      selected={activePathName === item.url || location.state?.activeUrl === item.url}
      onClick={() => itemHandler(item)}
    >
      <ListItemIcon sx={{ my: "auto", minWidth: 18 }}>
        <Icon
          stroke={2}
          size="1.5rem"
          color={theme.palette.primary.dark2}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            color="inherit"
            sx={{ marginLeft: "10px", fontSize: "14px", fontWeight: "500" }}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
