import React, { useState, useEffect, useContext } from 'react'

import Navigation from './router'
import { UserContext } from './state/userState'
import Loading from './components/Loading'

export default function Main() {
  const { setUser, user } = useContext(UserContext)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user) {
      setUser(user)
    }

    setReady(true)
  }, []) //eslint-disable-line

  if (!ready) return <Loading direct />

  return <Navigation />
}
