import React from "react";
import { Box, Typography } from "@mui/material";
const Logo = () => {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
    }} >
      <Typography
        variant="button"
        display="block"
        sx={{
          color: "#1F5296",
          fontSize: ".8rem",
          marginLeft: ".5rem",
          fontWeight: "bold",
          letterSpacing: "0px",
          lineHeight: ".8rem",
          textAlign: "left",
        }}
      >
        Guler Guler Admin Panel
      </Typography>
    </Box>
  );
};

export default Logo;
