import React, { useContext } from 'react'
// material-ui
import { useTheme } from '@mui/material/styles'
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  Paper,
  useMediaQuery
} from '@mui/material'

// third-party
import { BrowserView, MobileView } from 'react-device-detect'

// project imports
import MenuList from './MenuList'
import LogoSection from '../Header/LogoSection'

import { useNavigate } from 'react-router'
import { UserContext } from '../../state/userState'

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchUpMdWidth = '260px'

  const {setUser} = useContext(UserContext)

  const navigate = useNavigate()

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <MenuList />
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: matchUpMdWidth }}
          elevation={0}
        >
          <BottomNavigation showLabels>
            <BottomNavigationAction
              label="Çıkış Yap"
              onClick={() => {
                localStorage.removeItem('user')
                setUser(null)
                navigate('/login')
              }}
            />
          </BottomNavigation>
        </Paper>
      </BrowserView>
      <MobileView>
        <Box>
          <MenuList />
        </Box>
      </MobileView>
    </>
  )

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? matchUpMdWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: matchUpMd ? matchUpMdWidth : '260px',
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            paddingLeft: '15px',
            paddingRight: '15px',
            [theme.breakpoints.up('md')]: {
              top: '88px',
              height: 'calc(100% - 150px)'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar
